<template>
  <el-dialog
    v-model="showUploadCSVDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-import-upload-csv-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-import-upload-csv-dialog-header__title">
        {{ props.title }}
      </h4>
    </template>
    <el-form
      ref="uploadFormRef"
      :model="uploadForm"
      :rules="rules"
      label-position="top"
      :style="{ 'margin-bottom': !analyze ? '24px' : 0 }"
    >
      <template v-if="showAnalyze && analyze">
        <div class="elv-import-dialog-fileInfo">
          <div class="elv-import-dialog-fileInfo-file">
            <div>
              <SvgIcon name="csv-file" width="24" height="24" /><span>{{ uploadFile?.name }}</span>
            </div>
            <p @click="onReuploadFile">{{ t('button.reuploadFile') }}</p>
          </div>
          <div class="elv-import-dialog-fileInfo-total">
            <span class="elv-import-dialog-fileInfo-total__title">{{ t('report.totalLines') }}</span>
            <span class="elv-import-dialog-fileInfo-total__num">{{ formatNumber(fileData?.preview.total) }}</span>
          </div>
          <div class="elv-import-dialog-fileInfo-unrecognizable">
            <div class="elv-import-dialog-fileInfo-unrecognizable__title">
              {{ t('report.unrecognizableLines') }}
              <el-tooltip
                :show-arrow="false"
                effect="dark"
                placement="top"
                popper-class="elv-import-upload-tips"
                overlay-classname="elv-import-upload-tips"
                :show-after="500"
              >
                <div class="elv-source-upload-title-content">
                  <SvgIcon name="help-filled" width="16" height="16" />
                </div>
                <template #content>
                  <div class="elv-import-upload-tips-content">
                    {{ t('message.counterpartySomeLinesError') }}
                  </div>
                </template>
              </el-tooltip>
            </div>
            <span class="elv-import-dialog-fileInfo-unrecognizable__total">{{
              formatNumber(fileData.preview.unrecognizedCount)
            }}</span>
          </div>
          <div class="elv-import-dialog-fileInfo-recognized">
            <span class="elv-import-dialog-fileInfo-recognized__title">{{ t('report.recognizedLines') }}</span>
            <span class="elv-import-dialog-fileInfo-recognized__total">{{
              formatNumber(fileData.preview.recognizedCount)
            }}</span>
          </div>
        </div>
        <slot name="importExtendContent"></slot>
      </template>

      <div v-if="!showUpload && isSuccess && showSuccess" class="elv-import-upload-parsing-container">
        <div class="elv-import-upload-content">
          <SvgIcon name="sources-sync-done" width="32" height="32" />
          <p class="elv-import-upload-parsing-title">
            {{ t('report.uploadCompleted') }}
          </p>
        </div>
      </div>

      <el-form-item v-if="!analyze" prop="Upload">
        <el-upload
          v-show="showUpload"
          ref="uploadRef"
          class="elv-import-dialog-upload"
          drag
          multiple
          :show-file-list="false"
          :data="uploadData"
          :headers="{ Authorization: authorization }"
          :action="`${VITE_API_URL}/entity/${entityId}/csv/upload`"
          :before-upload="beforeCSVUpload"
          @error="onUploadError"
          @success="onUploadSuccess"
        >
          <SvgIcon name="source-upload" class="elv-source-upload__icon" width="32" height="32" />
          <div class="elv-import-upload__text">
            {{ t('report.dragUploadOrBrowse') }}<br /><span>{{ t('report.uploadCSVMaxSize') }}</span>
          </div>
          <template #tip>
            <div v-if="templateUrl" class="el-upload__tip">
              <span @click="onDownloadTemplateFile">{{ t('report.clickDownloadTemplate') }}</span>
            </div>
          </template>
        </el-upload>
        <div
          v-if="!showUpload && !isSuccess"
          class="elv-import-upload-parsing-container"
          :class="{ 'is-error': uploadError }"
        >
          <div class="elv-import-upload-content">
            <img v-if="!uploadError" src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
            <SvgIcon v-else name="sources-sync-error" width="32" height="32" />
            <p class="elv-import-upload-parsing-title">
              {{ uploadError ? t('report.parsingFailed') : t('report.parsingFile') }}
            </p>
            <p v-if="!uploadError" class="elv-import-upload-parsing-info">
              {{ uploadFile?.name }} ({{ fileSize }})<span @click="onCancelUpload">{{ t('button.cancel') }}</span>
            </p>
            <p v-else class="elv-import-upload-parsing-info">
              {{ t('report.unableRecognizeYourFile')
              }}<span @click="onReuploadFile">{{ t('button.uploadAnotherFile') }}</span>
            </p>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <div v-if="analyze" class="el-dialog__footer">
      <elv-button
        type="primary"
        round
        class="elv-accounts-dialog-footer__button"
        :loading="submitLoading"
        :disabled="!showSuccess && !importedTotal"
        @click="onSaveImport"
        >{{ submitText ? submitText : t('button.upload') }}</elv-button
      >
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { useCookies } from 'vue3-cookies'
import { formatNumber } from '@/lib/utils'
import { downloadFile } from '@/lib/download'
import type { UploadRawFile, FormInstance, FormRules, UploadProps } from 'element-plus'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  templateUrl: {
    type: String,
    required: false,
    default: ''
  },
  noPermission: {
    type: Boolean,
    required: true
  },
  showAnalyze: {
    type: Boolean,
    required: false,
    default: true
  },
  showSuccess: {
    type: Boolean,
    required: false,
    default: false
  },
  submitText: {
    type: String,
    required: false,
    default: ''
  },
  templateSlug: {
    type: String,
    required: true,
    default: ''
  }
})

const { t } = useI18n()
const route = useRoute()
const { cookies } = useCookies()
const { VITE_API_URL } = import.meta.env

const uploadForm: any = reactive({
  file: ''
})

const uploadRef = ref()
const submitLoading = ref(false)
const showUploadCSVDialog = ref(false)
const uploadError = ref(false) // 上传失败
const analyze = ref(false) // 是否解析完成
const showUpload = ref(true) // 是否显示上传组件
const isSuccess = ref(false) // 是否上传成功
const uploadFormRef = ref<FormInstance>()
const uploadFile: any = ref<UploadRawFile>()
const fileData: any = ref({
  entityFileId: 0,
  preview: {
    total: 0,
    unrecognizedCount: 0,
    recognizedCount: 0
  }
})

const rules = reactive<FormRules>({
  Upload: {
    required: true,
    trigger: 'blur'
  }
})
const emit = defineEmits(['onResetList', 'onSaveImport', 'onCloseDialog'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const fileSize = computed(() => {
  const units = ['bytes', 'kb', 'm']
  const k = 1024
  const i = Math.floor(Math.log(uploadFile.value?.size) / Math.log(k))
  // eslint-disable-next-line no-unsafe-optional-chaining
  const size = `${parseFloat((uploadFile.value?.size / k ** i).toFixed(2))}${units[i]}`
  return size
})

const authorization = computed(() => {
  return `Bearer ${cookies.get('elv-app-token')}`
})

const uploadData = computed(() => {
  return {
    templateSlug: props.templateSlug
  }
})

// eslint-disable-next-line no-unused-vars
const importedTotal = computed(() => {
  return fileData.value?.preview?.recognizedCount
})

// 切换弹窗展示
const onCheckUploadCSVDialog = () => {
  showUploadCSVDialog.value = !showUploadCSVDialog.value
}

const onReuploadFile = () => {
  analyze.value = false
  uploadError.value = false
  showUpload.value = true
  isSuccess.value = false
  uploadForm.file = ''
  fileData.value = {
    entityFileId: 0,
    preview: {
      total: 0,
      unrecognizedCount: 0,
      recognizedCount: 0
    }
  }
  uploadFile.value = undefined
}

const onFinallyImport = () => {
  submitLoading.value = false
}

const onSuccessImport = () => {
  ElMessage.success(t('message.success'))
  uploadFormRef.value?.resetFields()
  onCheckUploadCSVDialog()
  emit('onResetList')
}

const onSaveImport = async () => {
  if (!uploadFormRef.value) return
  await uploadFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      if (props.noPermission) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      submitLoading.value = true
      try {
        const params: any = {
          entityFileId: fileData.value.entityFileId,
          name: fileData.value.name
        }
        emit('onSaveImport', params)
      } catch (error: any) {
        console.log(error)
        ElMessage.error(error.message)
      }
    }
  })
}

const beforeCSVUpload: UploadProps['beforeUpload'] = (rawFile) => {
  let status = true
  if (rawFile.type !== 'text/csv') {
    ElMessage.error('Please upload CSV file!')
    status = false
  }
  const k = 1024
  if (rawFile.size / k / k > 100) {
    ElMessage.error('The file size can not exceed 100m!')
    status = false
  }
  if (status) uploadFile.value = rawFile
  if (uploadFile.value) {
    uploadError.value = false
    showUpload.value = false
    analyze.value = false
    isSuccess.value = false
  }
  return status
}

const onUploadError: UploadProps['onError'] = (error) => {
  uploadError.value = true
  showUpload.value = false
  analyze.value = false
  isSuccess.value = false
  const err = JSON.parse(error.message)
  ElMessage.error(err?.data?.message)
}

const onUploadSuccess: UploadProps['onSuccess'] = (response) => {
  analyze.value = true
  isSuccess.value = true
  fileData.value = response.data
}

const onCancelUpload = () => {
  onReuploadFile()
  uploadRef.value?.abort()
}

const onDownloadTemplateFile = () => {
  downloadFile(`https://static.elven.com/${props.templateUrl}`)
}

const resetFrom = () => {
  onReuploadFile()
  uploadFormRef.value?.resetFields()
}

const onCloseDialog = () => {
  resetFrom()
  emit('onCloseDialog')
}

watchEffect(() => {
  onReuploadFile()
  uploadForm.file = ''
})

defineExpose({ onCheckUploadCSVDialog, onSuccessImport, onFinallyImport })
</script>

<style lang="scss">
.elv-import-upload-csv-dialog {
  width: 620px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px !important;
    border-bottom: 1px solid #edf0f3;

    .elv-import-upload-csv-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }
    }

    .elv-import-dialog-upload {
      position: relative;

      .el-upload-dragger {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 572px;
        height: 200px;
        border: 1.5px dashed #dde1e6;
        padding: 59.5px 10px;
        transition: all 0.2s;

        &:hover {
          border-color: #1753eb;

          .elv-source-upload__icon {
            fill: #1753eb;
          }

          .elv-import-upload__text {
            color: #636b75;
          }
        }
      }

      .elv-source-upload__icon {
        fill: #aaafb6;
        margin-bottom: 16px;
        transition: all 0.2s;
      }

      .elv-import-upload__text {
        width: 232px;
        height: 33px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #aaafb6;
        transition: all 0.2s;

        span {
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          zoom: 0.91;
        }
      }

      .el-upload__tip {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        height: 14px;
        line-height: 14px;

        span {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 11px;
          text-decoration-line: underline;
          cursor: pointer;
          color: #1753eb;
        }
      }
    }

    .elv-import-upload-parsing-container {
      position: relative;

      .elv-import-upload-content {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 572px;
        height: 200px;
        border: 1.5px solid #dde1e6;
        background-color: #fff;
        border-radius: 8px;

        img {
          display: block;
          width: 32px;
          height: 32px;
          animation: upload-loading-rotate 2s linear infinite;
        }

        .elv-import-upload-parsing-title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #1e2024;
          margin-bottom: 6px;
          margin-top: 16px;
        }

        .elv-import-upload-parsing-info {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #636b75;

          span {
            cursor: pointer;
            text-decoration-line: underline;
            color: #5e85eb;
            margin-left: 8px;
          }
        }
      }
    }

    .elv-import-upload-tip {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 16px;

      span {
        color: #1753eb;
        cursor: pointer;
        text-decoration-line: underline;
      }
    }

    .elv-import-dialog-fileInfo {
      box-sizing: border-box;
      width: 572px;
      max-height: 245px;
      background: #f9fafb;
      border: 1px solid #edf0f3;
      border-radius: 8px;
      padding: 16px 22px 14px 20px;

      .elv-import-dialog-fileInfo-file {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
          display: flex;
          align-items: center;

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #1e2024;
            margin-left: 8px;
          }
        }

        p {
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
          text-decoration: underline;
          color: #5e85eb;
          cursor: pointer;
        }
      }

      .elv-import-dialog-fileInfo-total {
        margin-top: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 530px;
        height: 36px;
        border-top: 1px solid #edf0f3;
        border-bottom: 1px solid #edf0f3;

        .elv-import-dialog-fileInfo-total__title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #1e2024;
        }

        .elv-import-dialog-fileInfo-total__num {
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #1e2024;
        }
      }

      .elv-import-dialog-fileInfo-recognized {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 530px;
        height: 36px;
        border-top: 1px solid #edf0f3;
        border-bottom: 1px solid #edf0f3;

        .elv-import-dialog-fileInfo-recognized__title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #1e2024;
        }

        .elv-import-dialog-fileInfo-recognized__total {
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #1e2024;
        }
      }
    }

    .elv-import-dialog-fileInfo-unrecognizable {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 530px;
      height: 36px;

      &.mr-20 {
        width: 510px;
        margin-left: 20px;
        border-bottom: 1px solid #edf0f3;
      }

      .elv-import-dialog-fileInfo-unrecognizable__title {
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;

        &.discarded {
          color: #f05947;
        }
      }

      .elv-import-dialog-fileInfo-unrecognizable__total {
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;

        &.discarded {
          color: #f05947;
        }
      }
    }

    .elv-source-upload-title-content {
      height: 16px;
      margin-left: 6px;

      &:hover {
        svg {
          fill: #b0b3bc;
        }
      }

      svg {
        fill: #d6d9e0;
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-import-upload-tips {
  padding: 0px;
  border: 1px solid #e4e7eb !important;
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.15),
    0px 4px 8px 0px rgba(0, 0, 0, 0.04),
    0px 8px 16px 0px rgba(0, 0, 0, 0.04);
}

.elv-import-upload-tips-content {
  padding: 5px 10px 8px 10px;
  box-sizing: border-box;
  color: #fff;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  width: 173px;
}
</style>
